@import 'core';
@import 'animate';
@import 'autoheight';
@import 'lazyload';
@import 'video';

.owl-next, .owl-prev {
    
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 80ms ease-in-out;
    color: gray !important;
    background-color: transparent;    
    width: 70px;
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    zoom: 1;
    *display: inline; /*IE7 life-saver */
    padding: 20px 15px !important;
    margin: 0 !important;
    transition: all .2s ease-in-out;

    @media screen and (max-width: 768px){
         
            height: 50px;
            width: 50px;
            top: 30%;
            padding: 15px 10px !important;

            i {
                font-size: 1.5em !important;
            }

        }
    




    i {
        font-size: 2em;
        font-weight: 400;
    }
}

.owl-next {
    right: 0;
    outline: 0;
}

.owl-prev {
    left: 0;
    outline: 0;



}

.owl-nav {
    display: flex;
    opacity: 1;
    transition: all 0.25s ease-in-out;
}

.owl-carousel {

    .owl-stage {
        margin: 0 auto;
    }
    &:hover {
        .owl-nav {
            opacity: 1;
        }
    }
}

.owl-dots {
    text-align: center;
    padding: 2em;

    .owl-dot span {
        display:block;
        border-radius: 100% !important;
        background-color: #ccc !important;
        margin: 0.25em !important;
        padding: 0.5em !important;
        outline: 0;
    }

}

.owl-dot.active span{
    background-color: #2164f3 !important;
}